<template>
    <div class="main container">
        <div class="header">
            目前共计 2 个分类~
        </div>
        <div class="cmain">
            <div class="cmainL">
                <ul class="catelist">
                    <li class="item cur">
                        <a href="">前端开发</a>
                    </li>
                    <li class="item">
                        <a href="">cocos creator</a>
                    </li>
                </ul>
            </div>
            <div class="cmainR">
                <ul class="rlist">
                    <li class="item" v-for="(item,index) in [1,2,3,4,5,6,7,8,9,10]" :key="index">
                        <p class="time">2023-02-02 23:32:34</p>
                        <div class="contBox">
                            <h3 class="title">vue实现文章详情页左侧工具栏的位置变化{{item}}</h3>
                            <div class="tags">
                                <a href="">
                                    <span class="iconfont"></span>
                                    前端开发
                                </a>
                                <a href="">Vue</a>
                                <a href="">Java Script</a>
                                <a href="">Css</a>
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <p class="time">2023-02-02 23:32:34</p>
                        <div class="contBox">
                            <h3 class="title">vue实现文章详情页左侧工具栏的位置变化</h3>
                            <div class="tags">
                                <a href="">
                                    <span class="iconfont"></span>
                                    前端开发
                                </a>
                                <a href="">Vue</a>
                                <a href="">Java Script</a>
                                <a href="">Css</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="endline">我也是有底线的~</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>

</style>